<template>
    <div class="creater">
        <div class="cr-1f w-1400">
            <el-row :gutter="30">
                <el-col :span="12">
                    <div class="cr-zx">
                        <div class="cr-title clearfix">
                            <div class="fl">创客空间</div>
                            <div class="more fr">
                                <router-link to="/List?infoType=249253341319237&type=创客空间">
                                    更多<i class="el-icon-arrow-right"></i>
                                </router-link>
                            </div>
                            
                        </div>
                        <div class="zx-main mt-20">
                            <div class="zx-pic">
                                <el-image style="width:100%;height:100%" :src="CKKJdata[0].photoUrls"></el-image>
                                <div class="zx-title ellipsis">{{CKKJdata[0].title}}</div>
                            </div>
                            <div class="zx-list">
                                <ul>
                                    <li class="zx-list-item" v-for="item in CKKJdata" :key="item.id" :class="{act:item==1}" @click="goDetail(item.id,'创客空间',JSON.stringify(CKKJparams))">
                                        <div class="zx-list-text ellipsis">{{item.title}}</div>
                                        <div class="zx-list-time">{{item.releaseDate&&item.releaseDate.substr(0,10)}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="cr-title clearfix">
                        <div class="fl">创客故事</div>
                    </div>
                    <div class="gs-main mt-20">
                        <el-carousel trigger="click" height="500px"  arrow="never">
                            <el-carousel-item v-for="item in CKGSdata" :key="item.id">
                                <div class="gs-pic"> 
                                    <el-image style="width:100%;height:100%" :src="item.photoUrls" @click="goDetail(item.id,'创客故事',JSON.stringify(CKGSparams))"></el-image>
                                    <div class="gs-title ellipsis">{{item.title}}</div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="cr-2f w-1400">
            <div class="cr-title clearfix">
                <div class="fl"><i class="arrow-r"></i> 创客之星</div>
            </div>
            <div class="star-mian">
                <div class="cal-small">
                    <ul class="cal-small-list clearfix" :class="{istrans}" :style="`width:${245*calList.length*2}px;transform:translateX(${transNum}px)`">
                        <li class="cal-smal-item fl" :class="{isSlace:(Math.abs(transNum/245)+2 === index)}" v-for="(item,index) in calList" :key="index +'cal'">
                            <div class="star-pic">
                                <router-link :to="`detail?id=${item.id}&type=创客之星&oParams=${JSON.stringify(CKZSparams)}`">
                                    <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                </router-link>
                            </div>
                            <div class="star-info">
                                <div class="star-name">{{item.title&&item.title.substr(0,3)}}</div>
                                <div class="star-intro">
                                    {{item.title}}
                                </div>
                            </div>
                            <div class="ceng" v-show="!(Math.abs(transNum/245)+2 === index)"></div>
                        </li>
                    </ul>
                    <div class="cal-btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
                    <div class="cal-btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
        </div>
        <!--创客项目展-->
        <div class="cr-3f w-1400">
            <div class="cr-title clearfix">
                <div class="fl"><i class="arrow-r"></i> 创客项目展</div>
                <div class="more fr">
                    <router-link to="/List?infoType=259037113167941&type=创客项目展">
                        更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <div class="xm-main mt-20">
                <div class="xm-cal">
                    <el-carousel trigger="click" height="640px"  arrow="never">
                        <el-carousel-item v-for="item in CKXMZdata.slice(0,3)" :key="item.id">
                            <div class="xm-pic"> 
                                <router-link :to="`detail?id=${item.id}&type=创客项目展&oParams=${JSON.stringify(CKXMZparams)}`">
                                    <el-image style="width:100%;height:100%" :src="item.photoUrls"></el-image>
                                </router-link>
                                <div class="xm-info">
                                    <div class="ellipsis">{{item.title}}</div>
                                    <div class="ellipsis">{{regHtml(item.content)}}</div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="xm-list">
                    <el-row :gutter="30">
                        <el-col :span="12" v-for="item in CKXMZdata.slice(3,9)" :key="item.id + 'xm'">
                            <div class="xm-item clearfix">
                                <div class="fl xm-item-pic">
                                    <router-link :to="`detail?id=${item.id}&type=创客项目展&oParams=${JSON.stringify(CKXMZparams)}`">
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls"></el-image>
                                    </router-link>
                                </div>
                                <div class="xm-item-info fr">
                                    <div class="ellipsis" style="font-weight:600">{{item.title}}</div>
                                    <div class="xm-intro">
                                        {{regHtml(item.content)}}
                                    </div>
                                    <div class="xm-time"> {{item.releaseDate}}</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {GetList} from '@/api/other.js'
import {regHtml} from '@/utils/reg'
export default {
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
    data(){
        return{
            transNum:0,
            calList:[{},{},{},{},{}],
            istrans:true,
            actimg:'',
            CKKJparams:{//创客空间
                currentPage: 1,
                filter: {infoType:249253341319237,},
                pageSize: 5,
            },
            CKKJdata:[{}],
            CKGSparams:{//创客故事
                currentPage: 1,
                filter: {infoType:259036407320645,},
                pageSize: 4,
            },
            CKGSdata:[{}],
            CKZSparams:{//创客之星
                currentPage: 1,
                filter: {infoType:259036554174533,},
                pageSize: 6,
            },
            CKZSdata:[{}],
            CKXMZparams:{//创客项目展
                currentPage: 1,
                filter: {infoType:259037113167941,},
                pageSize: 10,
            },
            CKXMZdata:[{}],
        }
    },
    methods:{
        next(){
            if(this.transNum === -this.calList.length*245/2){
                this.transNum = 0
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum -= 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum -= 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        prev(){
            if(this.transNum === 0){
                this.transNum = -this.calList.length*245/2
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum += 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum += 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        goDetail(id,type,oParams){
            this.$router.push({path:'/detail',query:{id,type,oParams}})
        },
        getCKKJdata(){
            GetList(this.CKKJparams).then(res=>{
                this.CKKJdata = res.data.data.list;
            })
        },
        getCKGSdata(){
            GetList(this.CKGSparams).then(res=>{
                this.CKGSdata = res.data.data.list;
            })
        },
        getCKZSdata(){
            GetList(this.CKZSparams).then(res=>{
                this.CKZSdata = res.data.data.list;
                this.calList = res.data.data.list;
                this.calList =this.calList.concat(this.calList)//临时的 该用于获取数据后
            })
        },
        getCKXMZdata(){
            GetList(this.CKXMZparams).then(res=>{
                this.CKXMZdata = res.data.data.list;
            })
        },
    },
    mounted(){
        this.getCKKJdata();
        this.getCKGSdata();
        this.getCKZSdata();
        this.getCKXMZdata();
    }
}
</script>
<style scoped>
.xm-intro{
    line-height: 30px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
}
.xm-time{
    text-align: right;
    color: #999;
}
.xm-item-info{
    width: calc(100% - 235px);
    height: 200px;
    overflow: hidden;
    font-size: 16px;
    line-height: 40px;
    padding-top: 10px;
}
.xm-item-pic{
    width: 220px;
    height: 200px;
}
.xm-item{
    padding: 16px;
    background: #eee;
    margin-top:20px;
}
.xm-cal >>> .el-carousel__indicators--horizontal{
    bottom: 60px;
}
.xm-cal >>> .el-carousel__button{
    width: 50px;
    height: 10px;
}
.xm-info{
    width: 100%;
    padding:12px;
    height:60px;
    position: absolute;
    background: rgba(0, 0, 0, .6);
    color: #fff;
    left: 0;
    bottom: 0;
    font-size: 18px;
}
.xm-pic{
    height: 640px;
    position: relative;
}
.star-intro{
    line-height: 24px;
    padding: 4px 16px;
    height: 80px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
}
.star-name{
    font-size: 20px;
    font-weight: 600;
    padding-left: 24px;
    line-height: 28px;
}
.star-info{
    font-size: 17px;
    height: 400px;
}
.star-pic{
    height: 240px;
}
.isSlace{
    transform: scale(1.2);
    border: 2px solid #fff;
}
.isSlace .star-info{
    background: #f37b1c;
    color: #fff;
}
.istrans{
    transition: transform 1.2s;
}
.prev{
    left: 0;
}
.next{
    right: 0;
}
.cal-btn{
    width: 50px;
    height: 80px;
    line-height: 80px;
    font-size: 50px;
    font: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
}
.cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.ceng{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
}
.cal-smal-item{
    width: 220px;
    height: 370px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.cal-small{
    height: 520px;
    padding-top:34px;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.cr-title .arrow-r{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../assets/img/right2.png) no-repeat;
    background-size: 100% 100%;
    transform: translateY(2px);
}
.gs-main >>> .el-carousel__button{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    
}
.gs-main >>>.el-carousel__indicator.is-active button{
    background-color: #0e6ac1;
}
.gs-main >>> .el-carousel__indicators--horizontal{
    bottom: 50px;
    left: 80%;
}
.gs-title{
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 50px;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, .6);
    font-size: 20px;
}
.gs-pic{
    position: relative;
    height: 500px;
}
.zx-list-item .zx-list-text{
    width: calc(100% - 136px);
}
.zx-list-time{
    width: 100px;
    text-align: center;
}
.zx-list-item.act{
    color: #0e6ac1;
}
.zx-list-item:hover{
    color: #0e6ac1;
    cursor: pointer;
}
.zx-list-item{
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border-bottom: 1px dashed #eee;
}
.zx-list{
    margin-top: 20px;
    line-height: 32px;
    font-size: 16px;
}
.zx-title{
    line-height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    font-size: 20px;
    color: #fff;
    padding-left: 10px;
}
.zx-pic{
    height: 320px;
    position: relative;
}
.cr-title{
    height: 42px;
    line-height: 42px;
    font-size: 20px;
    border-bottom: 1px solid #E4E7ED;
}
.creater{
    font-family: '微软雅黑';
}
</style>